import gql from "graphql-tag"

export default gql`
  fragment cartReturn on Cart {
    checkoutUrl
    buyerIdentity {
      customer {
        id
      }
    }
    lines(first: 50) {
      edges {
        node {
          id
          quantity
         
          cost {
            amountPerQuantity {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              title
              selectedOptions {
                name
                value
              }
              price {
                amount
                currencyCode
              }
              image {
                width
                urlThumbnail: url(transform: { maxWidth: 350 })
                urlMedium: url(transform: { maxWidth: 1500 })
                url
                id
                height
                altText
              }
              product {
                title
                handle
              }
            }
          }
        }
      }
    }
    id
    createdAt
    updatedAt
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
    }
  }
`
