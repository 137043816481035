export const $mutate = (opts) => {
  const { $apolloClient } = useNuxtApp()

  const reqHeaders = useRequestHeaders()
  const headers = {}

  if (import.meta.server) {
    const visitorIP = reqHeaders?.["x-forwarded-for"] ?? null
    if (visitorIP) headers["Shopify-Storefront-Buyer-IP"] = visitorIP
  }

  return $apolloClient.mutate({
    ...opts,
    fetchPolicy: "no-cache",
    context: {
      headers,
    },
  })
}
