export enum CookieKeys {
  CustomerAccessToken = "customer_access_token",
  CountryCode = "country_code",
  CartId = "cart_id",
  TrackingControlConsent = "tracking_control_consent",
}

export enum ShopLoadingStates {
  Idle = "idle",
  Pending = "pending",
  Resolved = "resolved",
  Rejected = "rejected",
}

export enum LoadingState {
  Idle = "idle",
  Pending = "pending",
  Resolved = "resolved",
  Rejected = "rejected",
}

export enum SortValues {
  PriceAsc = "PRICE_ASC",
  PriceDesc = "PRICE_DESC",
  Price = "PRICE",
  Title = "TITLE",
  Created = "CREATED",
  TitleDesc = "TITLE_DESC",
  TitleAsc = "TITLE_ASC",
  DateDesc = "DATE_DESC",
  DateAsc = "DATE_ASC",
  BestSelling = "BEST_SELLING",
  ProductType = "PRODUCT_TYPE",
}

export enum HTTPMethod {
  CONNECT = "CONNECT",
  DELETE = "DELETE",
  GET = "GET",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
  TRACE = "TRACE",
}
