export default defineAppConfig({
  ui: {
    icons: {
      dynamic: true,
    },
    primary: "nasared",
    gray: "stone",
    button: {
      color: {
        white: {
          solid: "shadow-none ring-0",
        },
      },
      default: {
        size: "xl",
        color: "primary",
        variant: "solid",
        loadingIcon: "i-fa6-solid-spinner",
      },
    },
    modal: {
      overlay: {
        background: "bg-stone-200/75 dark:bg-gray-900/80",
      },
    },
    dropdown: {
      background: "dark:bg-white",
      item: {
        padding: "px-3",
        size: "text-md",
        inactive: "text-black dark:text-black",
      },
    },
    // input: {
    //   icon: {
    //     base: "flex-shrink-0 text-black",
    //     color: "text-{color}-500 dark:text-{color}-400",
    //   },
    // },
  },
})
