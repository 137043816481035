<template>
  <div
    id="cookie-banner"
    class="fixed inset-x-2 bottom-2 z-50"
  >
    <div
      class="flex flex-row items-center justify-center rounded border border-stone-700 bg-stone-900 py-3 pl-5 pr-3 text-white shadow-lg"
    >
      <div class="flex-0 mr-3">
        <span class="inline-block"><ClientOnly><font-awesome-icon :icon="['fas', 'circle-info']" /></ClientOnly></span>
      </div>
      <div class="flex-1 items-center justify-center pr-4">
        <p>
          We use cookies to analyze our traffic to enhance your browsing
          experience. By clicking "Accept" you consent to our use of cookies.
          Learn more:
          <nuxt-link
            to="/policies/privacy"
            class="underline"
          >Privacy Policy</nuxt-link>
        </p>
      </div>
      <div class="flex-0">
        <!-- <Button el="button" class="mr-2" variant="outline">Customize</Button> -->
        <Button
          el="button"
          class="mr-2"
          @click="onReject"
        >
          Reject
        </Button>
        <Button
          el="button"
          @click="onAccept"
        >
          Accept
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Cookies from "js-cookie"
import { CookieKeys } from "@/enums"

const store = useStore()
const { trackingConsentBannerVisible } = storeToRefs(store)

// useGTM();

// Import shop store

const onAccept = () => {
  Cookies.set(CookieKeys.TrackingControlConsent, true, { expires: 365 })

  const $gtm = useGTM()
  $gtm.enable(true)
  trackingConsentBannerVisible.value = false
}

const onReject = () => {
  const $gtm = useGTM()
  Cookies.remove(CookieKeys.TrackingControlConsent)
  $gtm.enable(false)
  trackingConsentBannerVisible.value = false
}
</script>
