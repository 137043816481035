import { CookieKeys } from "@/enums"

export default defineNuxtPlugin(async (nuxtApp) => {
  console.log("01.init.ts")

  if (import.meta.server) {
    console.log("init on server")
  }

  if (import.meta.client) {
    console.log("init on client")
  }

  console.log("fetching shop data")
  const { shopifyStorefrontAccessToken } = useRuntimeConfig().public
  const store = useStore()
  const cartId = useCookie(CookieKeys.CartId)

  if (import.meta.server) {
    const trackingControlConsent = useCookie(CookieKeys.TrackingControlConsent)

    if (trackingControlConsent.value) {
      store.trackingConsent = true
      store.trackingConsentBannerVisible = false
    }

    try {
      const { data } = await useAsyncData("shop-data", () =>
        store.fetchShopData(),
      )
      await useAsyncData("shop-subcollections", () =>
        store.fetchShopSubcollections(data.value),
      )
    }
    catch (error) {
      console.log(error)
    }
  }

  // Back button Nuxt data fetching temporary fix

  if (cartId.value) {
    await store.fetchCartById(cartId.value)
  }
})
