import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as callbackbkIA7U1NQOMeta } from "/vercel/path0/pages/auth/callback.vue?macro=true";
import { default as bgtestpjMJmeD0B9Meta } from "/vercel/path0/pages/bgtest.vue?macro=true";
import { default as cart3AXNnOUus9Meta } from "/vercel/path0/pages/cart.vue?macro=true";
import { default as _91_46_46_46collection_handle_93Y27YnQdqrQMeta } from "/vercel/path0/pages/collections/[...collection_handle].vue?macro=true";
import { default as indexKWzcwof1WgMeta } from "/vercel/path0/pages/collections/index.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as contactnewTHDMLXmZQuMeta } from "/vercel/path0/pages/contactnew.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as newb5wW3Vq40sMeta } from "/vercel/path0/pages/new.vue?macro=true";
import { default as _91article_slug_93zEM3OFGcIJMeta } from "/vercel/path0/pages/news/[article_slug].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as indexy9KbOHdOwVMeta } from "/vercel/path0/pages/policies/index.vue?macro=true";
import { default as privacyEGlYdlO2JVMeta } from "/vercel/path0/pages/policies/privacy.vue?macro=true";
import { default as refundsYNTX1cjfZfMeta } from "/vercel/path0/pages/policies/refunds.vue?macro=true";
import { default as shippingCEHTOoE6e8Meta } from "/vercel/path0/pages/policies/shipping.vue?macro=true";
import { default as terms_45of_45serviceO8WNUdBEkDMeta } from "/vercel/path0/pages/policies/terms-of-service.vue?macro=true";
import { default as _91_91variant_93_93gsGHywKj46Meta } from "/vercel/path0/pages/products/[handle]/[[variant]].vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as _91_46_46_46category_handle_9347JfjvEpgrMeta } from "/vercel/path0/pages/shop/[...category_handle].vue?macro=true";
import { default as indexN8QAORuvpUMeta } from "/vercel/path0/pages/shop/index.vue?macro=true";
import { default as supportJTqUQCOdxvMeta } from "/vercel/path0/pages/support.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as component_45stubUHgG5aOkzGMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubUHgG5aOkzG } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: accountLUTTRGMhg3Meta?.name ?? "account",
    path: accountLUTTRGMhg3Meta?.path ?? "/account",
    meta: accountLUTTRGMhg3Meta || {},
    alias: accountLUTTRGMhg3Meta?.alias || [],
    redirect: accountLUTTRGMhg3Meta?.redirect,
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: callbackbkIA7U1NQOMeta?.name ?? "auth-callback",
    path: callbackbkIA7U1NQOMeta?.path ?? "/auth/callback",
    meta: callbackbkIA7U1NQOMeta || {},
    alias: callbackbkIA7U1NQOMeta?.alias || [],
    redirect: callbackbkIA7U1NQOMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: bgtestpjMJmeD0B9Meta?.name ?? "bgtest",
    path: bgtestpjMJmeD0B9Meta?.path ?? "/bgtest",
    meta: bgtestpjMJmeD0B9Meta || {},
    alias: bgtestpjMJmeD0B9Meta?.alias || [],
    redirect: bgtestpjMJmeD0B9Meta?.redirect,
    component: () => import("/vercel/path0/pages/bgtest.vue").then(m => m.default || m)
  },
  {
    name: cart3AXNnOUus9Meta?.name ?? "cart",
    path: cart3AXNnOUus9Meta?.path ?? "/cart",
    meta: cart3AXNnOUus9Meta || {},
    alias: cart3AXNnOUus9Meta?.alias || [],
    redirect: cart3AXNnOUus9Meta?.redirect,
    component: () => import("/vercel/path0/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46collection_handle_93Y27YnQdqrQMeta?.name ?? "collections-collection_handle",
    path: _91_46_46_46collection_handle_93Y27YnQdqrQMeta?.path ?? "/collections/:collection_handle(.*)*",
    meta: _91_46_46_46collection_handle_93Y27YnQdqrQMeta || {},
    alias: _91_46_46_46collection_handle_93Y27YnQdqrQMeta?.alias || [],
    redirect: _91_46_46_46collection_handle_93Y27YnQdqrQMeta?.redirect,
    component: () => import("/vercel/path0/pages/collections/[...collection_handle].vue").then(m => m.default || m)
  },
  {
    name: indexKWzcwof1WgMeta?.name ?? "collections",
    path: indexKWzcwof1WgMeta?.path ?? "/collections",
    meta: indexKWzcwof1WgMeta || {},
    alias: indexKWzcwof1WgMeta?.alias || [],
    redirect: indexKWzcwof1WgMeta?.redirect,
    component: () => import("/vercel/path0/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactnewTHDMLXmZQuMeta?.name ?? "contactnew",
    path: contactnewTHDMLXmZQuMeta?.path ?? "/contactnew",
    meta: contactnewTHDMLXmZQuMeta || {},
    alias: contactnewTHDMLXmZQuMeta?.alias || [],
    redirect: contactnewTHDMLXmZQuMeta?.redirect,
    component: () => import("/vercel/path0/pages/contactnew.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: newb5wW3Vq40sMeta?.name ?? "new",
    path: newb5wW3Vq40sMeta?.path ?? "/new",
    meta: newb5wW3Vq40sMeta || {},
    alias: newb5wW3Vq40sMeta?.alias || [],
    redirect: newb5wW3Vq40sMeta?.redirect,
    component: () => import("/vercel/path0/pages/new.vue").then(m => m.default || m)
  },
  {
    name: _91article_slug_93zEM3OFGcIJMeta?.name ?? "news-article_slug",
    path: _91article_slug_93zEM3OFGcIJMeta?.path ?? "/news/:article_slug()",
    meta: _91article_slug_93zEM3OFGcIJMeta || {},
    alias: _91article_slug_93zEM3OFGcIJMeta?.alias || [],
    redirect: _91article_slug_93zEM3OFGcIJMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/[article_slug].vue").then(m => m.default || m)
  },
  {
    name: indexxeWP9UFz4RMeta?.name ?? "news",
    path: indexxeWP9UFz4RMeta?.path ?? "/news",
    meta: indexxeWP9UFz4RMeta || {},
    alias: indexxeWP9UFz4RMeta?.alias || [],
    redirect: indexxeWP9UFz4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexy9KbOHdOwVMeta?.name ?? "policies",
    path: indexy9KbOHdOwVMeta?.path ?? "/policies",
    meta: indexy9KbOHdOwVMeta || {},
    alias: indexy9KbOHdOwVMeta?.alias || [],
    redirect: indexy9KbOHdOwVMeta?.redirect,
    component: () => import("/vercel/path0/pages/policies/index.vue").then(m => m.default || m)
  },
  {
    name: privacyEGlYdlO2JVMeta?.name ?? "policies-privacy",
    path: privacyEGlYdlO2JVMeta?.path ?? "/policies/privacy",
    meta: privacyEGlYdlO2JVMeta || {},
    alias: privacyEGlYdlO2JVMeta?.alias || [],
    redirect: privacyEGlYdlO2JVMeta?.redirect,
    component: () => import("/vercel/path0/pages/policies/privacy.vue").then(m => m.default || m)
  },
  {
    name: refundsYNTX1cjfZfMeta?.name ?? "policies-refunds",
    path: refundsYNTX1cjfZfMeta?.path ?? "/policies/refunds",
    meta: refundsYNTX1cjfZfMeta || {},
    alias: refundsYNTX1cjfZfMeta?.alias || [],
    redirect: refundsYNTX1cjfZfMeta?.redirect,
    component: () => import("/vercel/path0/pages/policies/refunds.vue").then(m => m.default || m)
  },
  {
    name: shippingCEHTOoE6e8Meta?.name ?? "policies-shipping",
    path: shippingCEHTOoE6e8Meta?.path ?? "/policies/shipping",
    meta: shippingCEHTOoE6e8Meta || {},
    alias: shippingCEHTOoE6e8Meta?.alias || [],
    redirect: shippingCEHTOoE6e8Meta?.redirect,
    component: () => import("/vercel/path0/pages/policies/shipping.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceO8WNUdBEkDMeta?.name ?? "policies-terms-of-service",
    path: terms_45of_45serviceO8WNUdBEkDMeta?.path ?? "/policies/terms-of-service",
    meta: terms_45of_45serviceO8WNUdBEkDMeta || {},
    alias: terms_45of_45serviceO8WNUdBEkDMeta?.alias || [],
    redirect: terms_45of_45serviceO8WNUdBEkDMeta?.redirect,
    component: () => import("/vercel/path0/pages/policies/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: _91_91variant_93_93gsGHywKj46Meta?.name ?? "products-handle-variant",
    path: _91_91variant_93_93gsGHywKj46Meta?.path ?? "/products/:handle()/:variant?",
    meta: _91_91variant_93_93gsGHywKj46Meta || {},
    alias: _91_91variant_93_93gsGHywKj46Meta?.alias || [],
    redirect: _91_91variant_93_93gsGHywKj46Meta?.redirect,
    component: () => import("/vercel/path0/pages/products/[handle]/[[variant]].vue").then(m => m.default || m)
  },
  {
    name: searchBIFC2dE21WMeta?.name ?? "search",
    path: searchBIFC2dE21WMeta?.path ?? "/search",
    meta: searchBIFC2dE21WMeta || {},
    alias: searchBIFC2dE21WMeta?.alias || [],
    redirect: searchBIFC2dE21WMeta?.redirect,
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46category_handle_9347JfjvEpgrMeta?.name ?? "shop-category_handle",
    path: _91_46_46_46category_handle_9347JfjvEpgrMeta?.path ?? "/shop/:category_handle(.*)*",
    meta: _91_46_46_46category_handle_9347JfjvEpgrMeta || {},
    alias: _91_46_46_46category_handle_9347JfjvEpgrMeta?.alias || [],
    redirect: _91_46_46_46category_handle_9347JfjvEpgrMeta?.redirect,
    component: () => import("/vercel/path0/pages/shop/[...category_handle].vue").then(m => m.default || m)
  },
  {
    name: indexN8QAORuvpUMeta?.name ?? "shop",
    path: indexN8QAORuvpUMeta?.path ?? "/shop",
    meta: indexN8QAORuvpUMeta || {},
    alias: indexN8QAORuvpUMeta?.alias || [],
    redirect: indexN8QAORuvpUMeta?.redirect,
    component: () => import("/vercel/path0/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: supportJTqUQCOdxvMeta?.name ?? "support",
    path: supportJTqUQCOdxvMeta?.path ?? "/support",
    meta: supportJTqUQCOdxvMeta || {},
    alias: supportJTqUQCOdxvMeta?.alias || [],
    redirect: supportJTqUQCOdxvMeta?.redirect,
    component: () => import("/vercel/path0/pages/support.vue").then(m => m.default || m)
  },
  {
    name: testlK5QpQxp8eMeta?.name ?? "test",
    path: testlK5QpQxp8eMeta?.path ?? "/test",
    meta: testlK5QpQxp8eMeta || {},
    alias: testlK5QpQxp8eMeta?.alias || [],
    redirect: testlK5QpQxp8eMeta?.redirect,
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/products",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  }
]