<template>
  <UModal v-model="isSearchModalOpen" :transition>
    <UForm
      ref="formRef"
      :state="state"
      novalidate
      :validate-on="['submit']"
      @submit="onSubmit"
    >
      <UCard>
        <template #header>
          <div class="flex items-center justify-between">
            <h3 class="font-bold">Search Astronomerch</h3>
            <UButton
              variant="ghost"
              icon="i-heroicons-x-mark-20-solid"
              class="-my-1"
              @click="isSearchModalOpen = false"
            />
          </div>
        </template>

        <div class="space-y-4">
          <UFormGroup name="search">
            <FormInput
              v-model="state.query"
              type="search"
              size="xl"
              icon="i-fa6-solid-magnifying-glass"
              autofocus
            >
              <FormInputButton type="submit">Search</FormInputButton>
            </FormInput>

            <!-- <UInput type="search" size="lg" autofocus v-model="state.query" /> -->
          </UFormGroup>
        </div>

        <!-- <template #footer>
            <UButton type="submit" size="lg" :loading="isLoading"
              >Continue</UButton
            >
          </template> -->
      </UCard>
    </UForm>
  </UModal>
</template>

<script setup lang="ts">
import type { FormError, FormErrorEvent, FormSubmitEvent } from "#ui/types";

const store = useStore();
const { isSearchModalOpen } = storeToRefs(store);
const formRef: Ref<HTMLFormElement | null> = ref(null);
const transition: Ref<boolean> = ref(true);
const state = reactive({
  query: "",
});

// const onValidate = (state: any) => {};

const onSubmit = async (event: FormSubmitEvent<any>) => {
  if (state.query && state.query.trim() !== "") {
    transition.value = false;
    await nextTick();
    isSearchModalOpen.value = false;
    navigateTo({
      path: "/search",
      query: {
        q: state.query.trim(),
      },
    });
    state.query = "";
  }
};
</script>
