import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client/core";

export default defineNuxtPlugin(async (nuxtApp) => {
  const { shopifyStorefrontApiUrl, shopifyStorefrontAccessToken } =
    useRuntimeConfig().public;

  const httpLink = createHttpLink({
    uri: shopifyStorefrontApiUrl, // your endpoint
    headers: {
      "X-Shopify-Storefront-Access-Token": shopifyStorefrontAccessToken,
    },
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });

  return {
    provide: {
      apolloClient: client,
    },
  };
});
