<template>
  <div>
    <USlideover v-model="isMobileMenuOpen">
      <UCard
        class="flex flex-1 flex-col"
        :ui="{
          body: { base: 'flex-1' },
          ring: '',
          divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        }"
      >
        <template #header>
          <div class="flex items-center justify-between">
            <h3
              class="text-base font-semibold leading-6 text-gray-900 dark:text-white"
            >
              Navigation
            </h3>
            <UButton
              color="gray"
              variant="ghost"
              icon="i-heroicons-x-mark-20-solid"
              class="-my-1"
              @click="isMobileMenuOpen = false"
            />
          </div>
        </template>

        <UVerticalNavigation :links="menu" :ui="navUi" />
      </UCard>
    </USlideover>
  </div>
</template>

<script setup lang="ts">
const store = useStore();
const { isMobileMenuOpen, mainMenu } = storeToRefs(store);

const menu = computed(() =>
  mainMenu.value.map((item) => {
    const obj = {
      label: item.label,
      icon: item.icon,
      click: () => {
        isMobileMenuOpen.value = false;
        navigateTo(item.to);
      },
    };

    return obj;
  })
);

const navUi = {
  wrapper: "relative",
  font: "font-bold",
  size: "text-lg",
  active:
    "text-gray-900 dark:text-white before:bg-gray-100 dark:before:bg-gray-800",
  inactive:
    "text-black dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:before:bg-gray-50 dark:hover:before:bg-gray-800/50",
  label: "truncate relative pl-1",
  icon: {
    base: "flex-shrink-0 w-5 h-5",
    active: "text-gray-700 z-30",
    inactive:
      "text-gray-400 dark:text-gray-500 group-hover:text-gray-700 dark:group-hover:text-gray-200 z-30",
  },
};
</script>
