<template>
  <UButton :ui>
    <slot />
  </UButton>
</template>

<script setup lang="ts">
const ui = {
  font: "eurostile font-bold tracking-wider",
  size: {
    sm: "text-xs",
    md: "text-xs",
    lg: "text-xs",
    xl: "text-xs",
  },
  padding: {
    xs: "px-2.5 py-1.5",
    sm: "px-3 py-2",
    md: "px-3.5 py-2.5",
    lg: "px-4 py-3",
    xl: "px-4 py-3.5",
  },
  icon: {
    size: {
      "2xs": "h-3 w-3",
      "xs": "h-3 w-3",
      "sm": "h-3 w-3",
      "md": "h-3.5 w-3.5",
      "lg": "h-4 w-4",
      "xl": "h-4 w-4",
    },
  },
}
</script>
