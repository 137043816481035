<template>
  <div
    id="app"
    class="flex flex-col text-gray-800 selection:bg-red-600 selection:text-white"
  >
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <SiteAnnouncementBar v-if="announcement" />
      <NuxtPage class="nuxt-page" />
    </NuxtLayout>

    <SiteMobileMenu />
    <SiteSearchModal />

    <TrackingConsentBanner v-if="trackingConsentBannerVisible" />
  </div>
</template>

<script setup lang="ts">
import { theme } from "#tailwind-config";

const config = useRuntimeConfig();

console.log("App.vue loading");

const store = useStore();
const {
  isMobile,
  isMobileMenuOpen,
  currentBreakpoint,
  trackingConsentBannerVisible,
  announcement,
  shop,
} = storeToRefs(store);

const getCurrentBreakpoints = () =>
  Object.entries(theme.screens)
    .sort((a, b) => a[1] - b[1])
    .reverse()
    .find((entry) => window.innerWidth > parseInt(entry[1], 10))?.[0];

// Set current Tailwinds breakpoint
if (import.meta.client) {
  currentBreakpoint.value = getCurrentBreakpoints();
}

const onWindowResize = () => {
  currentBreakpoint.value = getCurrentBreakpoints();
  // If the mobile menu is open and the browser is resized above mobile breakpoints, close the menu so it won't remain open if the browser window is made smaller again
  if (!isMobile.value && isMobileMenuOpen.value) isMobileMenuOpen.value = false;
};

// initialize components based on data attribute selectors

onMounted(() => {
  if (import.meta.client) {
    window.addEventListener("resize", onWindowResize);
  }
});

const bodyClasses = computed(() => {
  if (isMobileMenuOpen.value) {
    return "light dark:bg-gray-900 bg-white overflow-hidden";
  } else {
    return "light dark:bg-gray-950 bg-white-800";
  }
});

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} - ${shop?.value?.name}`
      : `${shop?.value?.name} - ${shop?.value?.brand?.slogan}`;
  },
  bodyAttrs: () => {
    return {
      class: bodyClasses.value,
    };
  },
  link: [
    {
      rel: "icon",
      type: "image/png",
      href: "/favicon.png",
      media: "(prefers-color-scheme: light)",
    },
    {
      rel: "icon",
      type: "image/png",
      href: "/favicon-dark.png",
      media: "(prefers-color-scheme: dark)",
    },
  ],
});
</script>

<style>
html {
  height: -webkit-fill-available;
  scroll-behavior: auto !important;
}

#__nuxt {
  flex: 1;
  display: flex;
  flex-direction: column;
}

body {
  min-height: 100svh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}

#app {
  flex: 1;
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
