const _0 = "0"
const _none = "0"
const _sm = "4px"
const _DEFAULT = "8px"
const _md = "12px"
const _lg = "16px"
const _xl = "24px"
const _2xl = "40px"
const _3xl = "64px"
const config = { "0": _0, "none": _none, "sm": _sm, "DEFAULT": _DEFAULT, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl,  }
export { config as default, _0, _none, _sm, _DEFAULT, _md, _lg, _xl, _2xl, _3xl }