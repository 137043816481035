<template>
  <div
    class="flex flex-1 flex-col text-gray-800 selection:bg-red-600 selection:text-white"
  >
    <NuxtLayout name="error">
      <div class="flex flex-1 flex-col items-center justify-center border-t">
        <div
          class="space-y-8 rounded-lg border-2 border-gray-500 bg-white px-12 py-8 shadow-lg"
        >
          <header class="">
            <p class="am-heading font-light text-black">
              Error {{ error.statusCode }}
            </p>
            <h2 class="am-heading text-2xl font-bold text-black">
              {{ error.statusMessage }}
            </h2>
          </header>
        </div>
      </div>
    </NuxtLayout>
    <SiteMobileMenu />
    <SiteSearchModal />
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";

const store = useStore();
const { buildDate } = useRuntimeConfig().public;
const { shop, mainMenu, infoMenu, currentBreakpoint, socialAccountData } =
  storeToRefs(store);

const props = defineProps({
  error: Object as () => NuxtError,
});

const { error } = toRefs(props);

const handleError = async () => await clearError({ redirect: "/" });

useHead({
  title: () => `${error?.value.statusMessage}`,
});

onMounted(async () => {
  // await clearError();
});
</script>
