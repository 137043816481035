<template>
  <div
    id="announcement-bar"
    class="mb-4 rounded bg-stone-200"
  >
    <div
      class="container mx-auto py-3 text-center text-stone-900"
      v-html="contentHtml"
    />
  </div>
</template>

<script setup lang="ts">
import { convertSchemaToHtml } from "@thebeyondgroup/shopify-rich-text-renderer"
import { storeToRefs } from "pinia"

const store = useStore()
const { announcement } = storeToRefs(store)
const contentHtml = computed(() =>
  convertSchemaToHtml(JSON.parse(announcement.value.content)),
)
</script>

<style scoped>
#announcement-bar {
  flex: 0 0 auto;
}

#announcement-bar .content {
  padding: 10px 0;
  color: #fff;
}

:deep(.content p:last-of-type) {
  margin-bottom: 0;
}

:deep(.content p) {
  margin-bottom: 0.35rem;
}
</style>
