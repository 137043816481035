import { createGtm } from "@gtm-support/vue-gtm"

export default defineNuxtPlugin(async (nuxtApp) => {
  const { gtmId, gtmDebug } = useRuntimeConfig().public

  nuxtApp.vueApp.use(createGtm({
    id: gtmId,
    defer: false,
    compatibility: false,
    enabled: false, // ADD COOKIE CHECKING
    debug: gtmDebug,
    loadScript: true,
    vueRouter: useRouter(),
    trackOnNextTick: false,
  }))
})
