const _xs = "20rem"
const _sm = "24rem"
const _md = "28rem"
const _lg = "32rem"
const _xl = "36rem"
const _2xl = "42rem"
const _3xl = "48rem"
const _4xl = "56rem"
const _5xl = "64rem"
const _6xl = "72rem"
const _7xl = "80rem"
const config = { "xs": _xs, "sm": _sm, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl,  }
export { config as default, _xs, _sm, _md, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl }