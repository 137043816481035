<template>
  <div
    class="has-[input:focus]:ring-primary-500 rounded-lg p-1 ring-2 ring-inset"
    :class="[parentClass]"
  >
    <div class="flex space-x-2">
      <div class="flex-1">
        <UInput
          v-bind="attrsFiltered"
          :ui="mergedUI"
        >
          <template
            v-if="$slots?.leading"
            #leading
          >
            <slot name="leading" />
          </template>
          <template
            v-if="$slots?.trailing"
            #trailing
          >
            <slot name="trailing" />
          </template>
        </UInput>
      </div>
      <div class="flex-0 space-x-0.5">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { merge, omit } from "lodash-es"

const attrs = useAttrs()

const attrsFiltered = computed(() => omit(attrs, ["ui"]))
// const input = ref(null);
// const modelValue = defineModel();

const defaultUI = {
  color: {
    white: {
      outline: "bg-transparent shadow-none ring-0 focus:ring-0",
    },
    gray: {
      outline: "shadow-none ring-0 focus:ring-0",
    },
  },
  variant: {
    outline: "shadow-none ring-0 focus:ring-0",
  },
}

const parentUI = {
  color: {
    white: {
      outline: "bg-transparent",
      none: "bg-white",
    },
    gray: {
      outline: "",
    },
  },
  variant: {
    outline: "bg-transparent",
    none: "",
  },
}

const parentClass = computed(() => {
  let classes

  if (attrs.color) {
    if (attrs.variant) {
      classes = parentUI.color[attrs.color][attrs.variant]
    }
    else {
      classes = parentUI.color[attrs.color].none
    }
  }
  else if (attrs.variant) {
    classes = parentUI.variant[attrs.variant]
  }

  return classes
})

const mergedUI = computed(() => merge(defaultUI, attrs.ui))

defineOptions({
  inheritAttrs: false,
})
</script>
