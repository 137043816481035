// store/filters.js
import { defineStore } from "pinia";
import Cookies from "js-cookie";
import type {
  Localization,
  Shop,
  Cart,
  Country,
  Metaobject,
} from "@shopify/hydrogen-react/storefront-api-types";
import { useRuntimeConfig } from "#app";
import { CookieKeys } from "@/enums";
import cartReturn from "@/graphql/storefront/fragments/cartReturn";

export const useStore = defineStore("store", () => {
  // const { $apolloClient } = useNuxtApp();

  // State
  const cart: Ref<Partial<Cart | null>> = ref(null);
  const customerAccessToken: Ref<string | null> = ref(null);
  const shop: Ref<Partial<Shop | null>> = ref(null);
  const cartId = ref(null);
  const isLoggedIn = ref(false);
  const announcement = ref(null);
  const isSearchModalOpen: Ref<boolean> = ref(false);
  const socialAccountData = ref([]);
  const mobileMenuVisible: Ref<boolean> = ref(false);
  const isMobileMenuOpen: Ref<boolean> = ref(false);
  const currentBreakpoint: Ref<string> = ref("unknown");
  const localization: Ref<Partial<Localization | null>> = ref(null);
  const countryCode: Ref<string> = ref("US");
  const currentCountry: Ref<Partial<Country>> = ref({
    currency: {
      isoCode: "USD",
      name: "United States Dollar",
      symbol: "$",
    },
    isoCode: "US",
    name: "United States",
    unitSystem: "IMPERIAL_SYSTEM",
  });
  const collections: Ref<[] | null> = ref([]);
  const categories: Ref<[] | null> = ref([]);
  const trackingConsent: Ref<boolean> = ref(false);
  const trackingConsentBannerVisible: Ref<boolean> = ref(false);
  const infoMenu = ref([
    {
      label: "Shipping & Returns",
      to: "/policies/shipping",
    },
    {
      label: "Privacy Policy",
      to: "/policies/privacy",
    },
    {
      label: "Terms of Service",
      to: "/policies/terms-of-service",
    },
    // {
    //   label: "News & Updates",
    //   path: "/news",
    // },
    {
      label: "Contact Us",
      to: "/contact",
    },
  ]);

  // Getters

  const cartQuantity = computed(
    () =>
      cart.value?.lines?.edges.reduce(
        (accumulator, curr) => accumulator + curr.node.quantity,
        0
      ) || 0
  );

  const isMobile = computed(
    () =>
      currentBreakpoint.value === undefined ||
      currentBreakpoint.value === "sm" ||
      currentBreakpoint.value === "md"
  );

  const mainMenu = computed(() => {
    const topLevelCategories = categories.value.map((category) => [
      {
        label: category.title,
        to: "/shop/" + category.handle,
      },
    ]);
    const topLevelCollections = collections.value.map((collection) => [
      {
        label: collection.title,
        to: "/collections/" + collection.handle,
      },
    ]);

    return [
      {
        label: "Collections",
        to: "/collections",
        icon: "i-fa6-solid-grip",
        children: [
          [
            {
              label: "All Collections",
              to: "/collections",
            },
          ],
          ...topLevelCollections,
        ],
      },

      {
        label: "Apparel",
        icon: "i-fa6-solid-shirt",
        to: "/shop/apparel",
      },
      {
        label: "Wall Art",
        icon: "i-fa6-solid-image",
        to: "/shop/wall-art",
      },

      {
        label: "New Launches",
        icon: "i-fa6-solid-rocket",
        to: "/new",
      },

      {
        label: "Shop All",
        icon: "i-fa6-solid-cart-shopping",
        to: "/shop",
        children: [
          [
            {
              label: "All Categories",
              to: "/shop",
            },
          ],
          ...topLevelCategories,
        ],
      },
    ];
  });

  // const currentCountry = computed(() => {
  //   const defaultCountry = {
  //     currency: {
  //       isoCode: "USD",
  //       name: "United States Dollar",
  //       symbol: "$",
  //     },
  //     isoCode: "US",
  //     name: "United States",
  //     unitSystem: "IMPERIAL_SYSTEM",
  //   };

  //   const country = localization.value.availableCountries.find(
  //     (currency) => currency.isoCode == countryCode.value,
  //   );

  //   return country || defaultCountry;
  // });

  // Actions

  const fetchShopData = async () => {
    const query = gql`
      query (
        $collectionsHandle: String
        $categoriesHandle: String
        $socialAccountsType: String!
        $announcementsType: String!
      ) @inContext(country: US) {
        shop {
          name
          description
          moneyFormat
          primaryDomain {
            host
            sslEnabled
            url
          }
          brand {
            slogan
            logo {
              alt
              image {
                url
              }
            }
          }
        }
        localization {
          availableCountries {
            currency {
              isoCode
              name
              symbol
            }
            isoCode
            name
            unitSystem
          }
          country {
            currency {
              isoCode
              name
              symbol
            }
            isoCode
            name
            unitSystem
          }
        }
        collections: collection(handle: $collectionsHandle) {
          subcollections: metafield(
            namespace: "astronomerch"
            key: "subcollections"
          ) {
            id
          }
        }
        categories: collection(handle: $categoriesHandle) {
          subcollections: metafield(
            namespace: "astronomerch"
            key: "subcollections"
          ) {
            id
          }
        }
        socialAccounts: metaobjects(type: $socialAccountsType, first: 100) {
          nodes {
            id
            handle
            fields {
              key
              type
              value
            }
          }
        }
        announcements: metaobjects(type: $announcementsType, first: 1) {
          nodes {
            id
            handle
            fields {
              key
              type
              value
            }
          }
        }
      }
    `;

    const variables = {
      collectionsHandle: "collections",
      categoriesHandle: "categories",
      socialAccountsType: "social_media",
      announcementsType: "announcements",
    };

    try {
      const { data } = await $query({ query, variables });

      console.log("FETCH SHOP DATA");
      console.log(data);
      // Overall shop metadata
      shop.value = data.shop;

      // Shop localization data
      localization.value = data.localization;

      // Shop social media account data
      if (data.socialAccounts.nodes.length) {
        console.log("Social accounts found");
        console.log(data.socialAccounts.nodes[0]);

        console.log("SOCIAL ACCOUNTS");
        socialAccountData.value = data.socialAccounts.nodes.map(
          (node: Metaobject) => ({
            handle: node.handle,
            account_handle: node.fields.find(
              (field) => field.key == "account_handle"
            ).value,
            icon: node.fields.find((field) => field.key == "icon").value,
            name: node.fields.find((field) => field.key == "name").value,
            url: node.fields.find((field) => field.key == "url").value,
          })
        );
      }

      if (data.announcements.nodes.length) {
        console.log("Announcements found");
        announcement.value = data.announcements.nodes.map(
          (node: Metaobject) => ({
            handle: node.handle,
            content: node.fields.find((field) => field.key == "content").value,
          })
        )[0];
      }

      // Site structure
      return {
        collections: data.collections,
        categories: data.categories,
      };
      // console.log(shopData.value.data.socialAccounts.nodes);
    } catch (error) {
      console.log("asyncdata error");
      console.log(error);
      return false;
    }
  };

  const fetchShopSubcollections = async (subcollectionData) => {
    const query = gql`
      query (
        $first: Int
        $last: Int
        $afterCursor: String
        $beforeCursor: String
        $collectionIds: [ID!]!
        $categoryIds: [ID!]!
      ) {
        collections: nodes(ids: $collectionIds) {
          ... on Metafield {
            id
            references(
              first: $first
              last: $last
              after: $afterCursor
              before: $beforeCursor
            ) {
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              nodes {
                ... on Collection {
                  handle
                  title
                }
              }
            }
          }
        }
        categories: nodes(ids: $categoryIds) {
          ... on Metafield {
            id
            references(
              first: $first
              last: $last
              after: $afterCursor
              before: $beforeCursor
            ) {
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              nodes {
                ... on Collection {
                  handle
                  title
                }
              }
            }
          }
        }
      }
    `;

    try {
      const variables = {
        collectionIds: [subcollectionData.collections.subcollections.id],
        categoryIds: [subcollectionData.categories.subcollections.id],
        first: 100,
      };

      const { data } = await $query({ query, variables });

      console.log("subcollectionsData");

      console.log(result);

      collections.value = data.collections[0].references.nodes;
      categories.value = data.categories[0].references.nodes;

      // shopLoadState.value = SHOP_LOADING_STATES.RESOLVED;

      return { collections: collections.value, categories: categories.value };
    } catch (error) {
      console.log;
      return false;
    }
  };

  const createNewCart = async (cartLines) => {
    if (!cartLines) return false;

    const mutation = gql`
      mutation cartCreate($input: CartInput!) {
        cartCreate(input: $input) {
          cart {
            ...cartReturn
          }
        }
      }
      ${cartReturn}
    `;

    try {
      const variables = {
        input: {
          lines: cartLines,
          buyerIdentity: {
            countryCode: countryCode.value,
          },
        },
      };

      // if (customerAccessToken.value) {
      //   const obj = {
      //     customerAccessToken: customerAccessToken.value.accessToken,
      //     email: this.customer.email,
      //     deliveryAddressPreferences: {
      //       deliveryAddress: this.customer.defaultAddress,
      //     },
      //   };
      //   variables = { ...variables, ...obj };
      // }

      const { data } = await $mutate({ mutation, variables });

      console.log("createNewCart");
      console.log(data);

      if (!data) throw new Error("no data");

      // this.cartId = data.data.cartCreate.cart.id;
      Cookies.set(CookieKeys.CartId, data.cartCreate.cart.id);
      cart.value = data.cartCreate.cart;
      return true;
    } catch (error) {
      return false;
    }
  };

  const addToCart = async (cartLines) => {
    console.log("addToCart");

    if (!cartLines || !cart.value) return false;

    const mutation = gql`
      mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            ...cartReturn
          }
        }
      }
      ${cartReturn}
    `;

    const variables = {
      cartId: cart.value.id,
      lines: cartLines,
    };

    try {
      const { data } = await $mutate({ mutation, variables });

      console.log(data);
      if (!data) throw new Error("no data");
      cart.value = data.cartLinesAdd.cart;
      Cookies.set(CookieKeys.CartId, data.cartLinesAdd.cart.id);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const fetchCartById = async (cartId) => {
    console.log("fetchCartById");
    if (!cartId) return false;

    console.log("cart found");
    const query = gql`
      query ($id: ID!, $countryCode: CountryCode)
      @inContext(country: $countryCode) {
        cart(id: $id) {
          ...cartReturn
        }
      }
      ${cartReturn}
    `;

    const variables = {
      id: cartId,
      countryCode: countryCode.value,
    };

    try {
      const { data } = await $query({ query, variables });

      console.log(data);
      if (!data) throw new Error("no data");
      cart.value = data.cart;
      return data.cart;
    } catch (error) {
      return null;
    }
  };

  const updateCart = async (cartLines) => {
    if (!cartLines) return false;

    console.log("updateCart");
    const mutation = gql`
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            ...cartReturn
          }
        }
      }
      ${cartReturn}
    `;

    const variables = {
      cartId: cart.value.id,
      lines: cartLines,
    };

    const { data } = await $mutate({ mutation, variables });
    console.log(data);
    if (!data) throw new Error("no data");
    cart.value = data.cartLinesUpdate.cart;
    return data.cartLinesUpdate.cart;
  };

  return {
    shop,
    cart,
    cartId,
    isLoggedIn,
    announcement,
    socialAccountData,
    mobileMenuVisible,
    isMobileMenuOpen,
    currentBreakpoint,
    localization,
    countryCode,
    collections,
    categories,
    trackingConsent,
    isSearchModalOpen,
    trackingConsentBannerVisible,
    infoMenu,
    cartQuantity,
    isMobile,
    mainMenu,
    currentCountry,
    fetchShopData,
    fetchShopSubcollections,
    createNewCart,
    addToCart,
    fetchCartById,
    updateCart,
  };
});
